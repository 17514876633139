import React, { useEffect } from "react";

export const OAuthCallback = () => {
  useEffect(() => {
    const redirectUri = localStorage.getItem("redirect_uri");
    if (redirectUri) {
      window.location.replace(redirectUri);
    }
  });

  return <></>;
};
