export const settings = {
  PUBLIC_URL: process.env.PUBLIC_URL || "",
  API_URL: "http://localhost:3001/api",
  COOKIE_DOMAIN: "localhost",
  OAUTH_TYPES: [
    {
      name: "Github",
      type: "github",
      icon: `${process.env.PUBLIC_URL}/github.svg`,
    },
  ],
};

export const fetchSettings = async () => {
  const response = await fetch("/config.json");
  if (!response.ok) {
    throw new Error("Failed to fetch config");
  }
  Object.assign(settings, await response.json());
};
