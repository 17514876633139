import { EmailIcon } from "@chakra-ui/icons";
import { Button, Container, HStack, Stack } from "@chakra-ui/react";
import { Form, Formik, type FormikHelpers } from "formik";
import React, { useCallback, useMemo } from "react";
import { object, string } from "yup";
import { usePostResetPassword } from "../api/auth";
import { InputField } from "../components/InputField";
import { useNotifications } from "../providers/NotificationProvider";

interface PasswordResetData {
  email: string;
}

export function PasswordResetForm() {
  const { mutateAsync: resetPassword } = usePostResetPassword();
  const { addNotification } = useNotifications();

  const onSubmitHandler = useCallback(
    async (
      { email }: PasswordResetData,
      { resetForm }: FormikHelpers<PasswordResetData>,
    ) => {
      try {
        const resp = await resetPassword({ data: { email } });

        if (resp.status == 200) {
          resetForm();
          addNotification({
            message:
              "Anfrage zum Zurücksetzen des Passworts erfolgreich. Bitte checke deine E-Mails.",
            type: "success",
          });
        } else {
          addNotification({
            message:
              "Bei der Anfrage des Passworts ist ein Fehler aufgetreten.",
            type: "error",
          });
        }
      } catch (errors) {
        (errors as any).forEach((e: string) => {
          addNotification({ message: e, type: "error" });
        });
        resetForm();
      }
    },
    [resetPassword, addNotification],
  );

  const initialValues: PasswordResetData = {
    email: "",
  };

  const passwordResetValidationSchema = useMemo(
    () =>
      object({
        email: string().required().email("Keine gültige E-Mail-Adresse"),
      }),
    [],
  );

  return (
    <Container maxW="full">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        validationSchema={passwordResetValidationSchema}
      >
        <Form>
          <Stack spacing={4}>
            <InputField
              icon={<EmailIcon color="gray.300" />}
              label="E-Mail"
              type="email"
              name="email"
            />
            <HStack>
              <Button colorScheme="red" type="submit">
                Passwort ändern
              </Button>
            </HStack>
          </Stack>
        </Form>
      </Formik>
    </Container>
  );
}
