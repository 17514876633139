import { ChatIcon, EmailIcon, RepeatIcon } from "@chakra-ui/icons";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Heading,
  Stack,
} from "@chakra-ui/react";
import { Form, Formik, type FormikHelpers } from "formik";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { object, ref, string } from "yup";
import {
  usePostRegistration,
  usePostRegistrationActivation,
} from "../api/auth";
import { InputField } from "../components/InputField";
import { useNotifications } from "../providers/NotificationProvider";

interface RegistrationData {
  email: string;
  password: string;
  passwordRepeat: string;
}

export function Registration() {
  const { mutateAsync: register } = usePostRegistration();
  const { mutateAsync: registerActivation } = usePostRegistrationActivation();
  const { addNotification } = useNotifications();
  const [searchParams, setSearchParams] = useSearchParams();

  const submitActivationToken = useCallback(
    async (token: string) => {
      try {
        const resp = await registerActivation({ data: { token } });

        if (resp.status == 200) {
          addNotification({
            message:
              "Erfolgreich registriert. Bitte checke deine E-Mails, um dein Konto zu bestätigen.",
            type: "success",
          });
        } else {
          addNotification({
            message: "Bei der Registrierung trat ein Fehler auf.",
            type: "error",
          });
        }
      } catch (errors) {
        (errors as any).forEach((e: string) => {
          addNotification({ message: e, type: "error" });
        });
      }
    },
    [registerActivation, addNotification],
  );

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      setSearchParams({});
      submitActivationToken(token);
    }
  }, []);

  const onSubmitHandler = useCallback(
    async (
      { email, password }: RegistrationData,
      { resetForm, setFieldValue }: FormikHelpers<RegistrationData>,
    ) => {
      try {
        const resp = await register({ data: { email, password } });

        if (resp.status == 200) {
          resetForm();
          addNotification({
            message:
              "Erfolgreich registriert. Bitte checke deine E-Mails, um dein Konto zu bestätigen.",
            type: "success",
          });
        } else {
          setFieldValue("password", "");
          setFieldValue("passwordRepeat", "");
          addNotification({
            message: "Bei der Registrierung trat ein Fehler auf.",
            type: "error",
          });
        }
      } catch (errors) {
        (errors as any).forEach((e: string) => {
          addNotification({ message: e, type: "error" });
        });
        resetForm();
      }
    },
    [register],
  );

  const initialValues: RegistrationData = {
    email: "",
    password: "",
    passwordRepeat: "",
  };

  const registrationValidationSchema = useMemo(
    () =>
      object({
        email: string().required().email("Keine gültige E-Mail-Adresse"),
        password: string()
          .required()
          .min(6, "Passwort muss mindestens 6 Zeichen lang sein")
          .max(255, "Passwort darf höchstens 255 Zeichen lang sein"),
        passwortRepeat: string().oneOf(
          [ref("password")],
          "Passwörter stimmen nicht überein",
        ),
      }),
    [],
  );

  return (
    <Container maxW="full" p={5}>
      <Card>
        <CardHeader>
          <Heading size="md">Registrierung</Heading>
        </CardHeader>

        <CardBody>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmitHandler}
            validationSchema={registrationValidationSchema}
          >
            <Form>
              <Stack spacing={4}>
                <InputField
                  icon={<EmailIcon color="gray.300" />}
                  label="E-Mail"
                  type="email"
                  name="email"
                />
                <InputField
                  icon={<ChatIcon color="gray.300" />}
                  label="Passwort"
                  type="password"
                  name="password"
                  autoComplete="new-password"
                />
                <InputField
                  icon={<RepeatIcon color="gray.300" />}
                  label="Passwortwiederholung"
                  type="password"
                  name="passwordRepeat"
                  autoComplete="new-password"
                />
                <Button colorScheme="red" type="submit">
                  Registrieren
                </Button>
              </Stack>
            </Form>
          </Formik>
        </CardBody>
      </Card>
    </Container>
  );
}
