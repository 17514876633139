import {
  Input,
  InputGroup,
  InputLeftElement,
  type InputProps,
} from "@chakra-ui/react";
import { useField } from "formik";
import React, { type ReactNode } from "react";

interface InputFieldProps extends InputProps {
  name: string;
  label?: string;
  icon?: ReactNode;
}

export function InputField({
  name,
  id = name,
  label,
  onChange,
  icon,
  ...rest
}: Readonly<InputFieldProps>) {
  const [field] = useField(name);
  return (
    <InputGroup>
      {icon && <InputLeftElement pointerEvents="none">{icon}</InputLeftElement>}
      <Input
        id={id}
        {...field}
        placeholder={label}
        onChange={(e) => {
          field.onChange(e);
          if (onChange) {
            onChange(e);
          }
        }}
        {...rest}
      />
    </InputGroup>
  );
}
