import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Heading,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { PasswordResetForm } from "./PasswordResetForm";
import { PasswordResetSubmitForm } from "./PasswordResetSubmitForm";

export function PasswordReset() {
  const [searchParams] = useSearchParams();
  const token = useMemo(() => searchParams.get("token"), [searchParams]);

  return (
    <Container maxW="full" p={5}>
      <Card>
        <CardHeader>
          <Heading size="md">Passwort zurücksetzen</Heading>
        </CardHeader>

        <CardBody>
          {token ? <PasswordResetSubmitForm /> : <PasswordResetForm />}
        </CardBody>
      </Card>
    </Container>
  );
}
