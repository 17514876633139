import React, {
  createContext,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

type NotificationType = "info" | "success" | "warning" | "error";

interface NotificationData {
  type: NotificationType;
  message: string | object;
}

interface Notification extends NotificationData {
  id: string;
  createdAt: number;
  dismissAfter?: number;
}

interface NotificationsContext {
  readonly notifications: Notification[];
  addNotification: (notification: NotificationData) => void;
  dismissNotification: (id: string) => void;
}

const NotificationContext = createContext<NotificationsContext>(
  {} as NotificationsContext,
);

interface NotificationsProviderProps {
  children: ReactNode;
  autoDismiss?: number;
}

export function NotificationsProvider({
  autoDismiss,
  children,
}: Readonly<NotificationsProviderProps>) {
  const [{ notifications }, setNotifications] = useState<{
    notifications: Notification[];
    nextId: number;
  }>({ notifications: [], nextId: 1 });

  const addNotification = useCallback(
    (notification: NotificationData) => {
      setNotifications(({ notifications: ns, nextId }) => ({
        notifications: [
          ...ns,
          {
            ...notification,
            id: nextId.toString(),
            createdAt: Date.now(),
            dismissAfter: autoDismiss && Date.now() + autoDismiss,
          },
        ],
        nextId: nextId + 1,
      }));
    },
    [setNotifications, autoDismiss],
  );

  const dismissNotification = useCallback(
    (id: string) => {
      setNotifications(({ notifications: ns, nextId }) => ({
        notifications: ns.filter((n) => n.id !== id),
        nextId,
      }));
    },
    [setNotifications],
  );

  useEffect(() => {
    if (autoDismiss) {
      const timer = setInterval(() => {
        const now = Date.now();
        setNotifications((state) => {
          const filtered = state.notifications.filter(
            (n) => !n.dismissAfter || n.dismissAfter > now,
          );
          return filtered.length !== state.notifications.length
            ? {
                notifications: filtered,
                nextId: state.nextId,
              }
            : state;
        });
      }, 10000);
      return () => {
        clearInterval(timer);
      };
    }
    return undefined;
  }, [setNotifications, autoDismiss]);

  const value: NotificationsContext = useMemo(
    () => ({
      notifications,
      addNotification,
      dismissNotification,
    }),
    [addNotification, dismissNotification, notifications],
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
}

export function useNotifications() {
  const context = React.useContext(NotificationContext);
  if (!context) {
    throw new Error("useNotifications() requires a <NotificationProvider />");
  }
  return context;
}
