import { ChatIcon, RepeatIcon } from "@chakra-ui/icons";
import { Button, Container, HStack, Stack } from "@chakra-ui/react";
import { Form, Formik, type FormikHelpers } from "formik";
import React, { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { object, ref, string } from "yup";
import { InputField } from "../components/InputField";
import { useNotifications } from "../providers/NotificationProvider";
import { usePostResetPasswordSubmit } from "../api/auth";

interface PasswordResetData {
  password: string;
  passwordRepeat: string;
}

export function PasswordResetSubmitForm() {
  const { mutateAsync: passwordResetSubmit } = usePostResetPasswordSubmit();
  const { addNotification } = useNotifications();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = useMemo(() => searchParams.get("token"), [searchParams]);

  const onSubmitHandler = useCallback(
    async (
      { password }: PasswordResetData,
      { resetForm, setFieldValue }: FormikHelpers<PasswordResetData>,
    ) => {
      try {
        if (!token) {
          addNotification({ message: "Token fehlt", type: "error" });
          return;
        }
        const resp = await passwordResetSubmit({
          data: {
            token,
            newPassword: password,
          },
        });

        if (resp.status == 200) {
          resetForm();
          setSearchParams({});
          addNotification({
            message:
              "Passwort erfolgreich zurückgesetzt. Bitte logge dich ein.",
            type: "success",
          });
        } else {
          setFieldValue("password", "");
          setFieldValue("passwordRepeat", "");
          addNotification({
            message:
              "Bei der Anfrage des Passworts ist ein Fehler aufgetreten.",
            type: "error",
          });
        }
      } catch (errors) {
        (errors as any).forEach((e: string) => {
          addNotification({ message: e, type: "error" });
        });
        resetForm();
      }
    },
    [passwordResetSubmit, token],
  );

  const initialValues: PasswordResetData = {
    password: "",
    passwordRepeat: "",
  };

  const passwordResetValidationSchema = useMemo(
    () =>
      object({
        password: string()
          .required()
          .min(6, "Passwort muss mindestens 6 Zeichen lang sein")
          .max(255, "Passwort darf höchstens 255 Zeichen lang sein"),
        passwortRepeat: string().oneOf(
          [ref("password")],
          "Passwörter stimmen nicht überein",
        ),
      }),
    [],
  );

  return (
    <Container maxW="full">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        validationSchema={passwordResetValidationSchema}
      >
        <Form>
          <Stack spacing={4}>
            <InputField
              icon={<ChatIcon color="gray.300" />}
              label="Passwort"
              type="password"
              name="password"
              autoComplete="new-password"
            />
            <InputField
              icon={<RepeatIcon color="gray.300" />}
              label="Passwortwiederholung"
              type="password"
              name="passwordRepeat"
              autoComplete="new-password"
            />
            <HStack>
              <Button colorScheme="red" type="submit">
                Passwort ändern
              </Button>
            </HStack>
          </Stack>
        </Form>
      </Formik>
    </Container>
  );
}
