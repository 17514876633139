/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * rx2b auth
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import { createAxiosInstance } from './mutator';
import type { ErrorType } from './mutator';
export type GetAuthorizeResponseType = typeof GetAuthorizeResponseType[keyof typeof GetAuthorizeResponseType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAuthorizeResponseType = {
  code: 'code',
} as const;

export type GetAuthorizeParams = {
client_id: string;
redirect_uri: string;
response_type: GetAuthorizeResponseType;
scope?: string;
state?: string;
};

/**
 * The type of the token
 */
export type TokenResponseTokenType = typeof TokenResponseTokenType[keyof typeof TokenResponseTokenType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TokenResponseTokenType = {
  Bearer: 'Bearer',
} as const;

export interface TokenResponse {
  /** The access token issued by the authorization server */
  access_token?: string;
  /** The lifetime in seconds of the access token */
  expires_in?: number;
  /** The refresh token, used to obtain new access tokens */
  refresh_token?: string;
  /** The type of the token */
  token_type?: TokenResponseTokenType;
}

export interface OAuthResponse {
  /** */
  redirectUrl?: string;
}

export interface ResetPasswordSubmissionRequest {
  /** */
  newPassword?: string;
  /** */
  token?: string;
}

export interface ResetPasswordRequest {
  /** */
  email?: string;
}

export interface ChangePasswordRequest {
  /** */
  newPassword?: string;
  /** */
  oldPassword?: string;
}

export interface RegistrationActivationRequest {
  /** */
  token?: string;
}

export interface RegistrationRequest {
  /** */
  email?: string;
  /** */
  password?: string;
}

export interface ErrorResponse {
  /** */
  description?: string;
  /** */
  key?: string;
}

export interface User {
  /** */
  email?: string;
  /** */
  externalId?: string;
  /** @nullable */
  githubAvatarUrl?: string | null;
  /** @nullable */
  githubId?: string | null;
  /** @nullable */
  githubLogin?: string | null;
  /** */
  name?: string;
  /** */
  roles?: string[];
}

export interface TokenRefreshRequest {
  /** */
  refreshToken?: string;
}

export interface HealthResponse {
  status?: string;
}

export interface LoginResponse {
  /** */
  token?: string;
}

export interface LoginRequest {
  /** */
  email?: string;
  /** */
  password?: string;
}




type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getHealth = (
    
 options?: SecondParameter<typeof createAxiosInstance>,signal?: AbortSignal
) => {
      
      
      return createAxiosInstance<HealthResponse>(
      {url: `/health`, method: 'GET', signal
    },
      options);
    }
  

export const getGetHealthQueryKey = () => {
    return [`/health`] as const;
    }

    
export const getGetHealthQueryOptions = <TData = Awaited<ReturnType<typeof getHealth>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData>>, request?: SecondParameter<typeof createAxiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHealthQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHealth>>> = ({ signal }) => getHealth(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHealthQueryResult = NonNullable<Awaited<ReturnType<typeof getHealth>>>
export type GetHealthQueryError = ErrorType<unknown>


export function useGetHealth<TData = Awaited<ReturnType<typeof getHealth>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getHealth>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof createAxiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetHealth<TData = Awaited<ReturnType<typeof getHealth>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getHealth>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof createAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetHealth<TData = Awaited<ReturnType<typeof getHealth>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData>>, request?: SecondParameter<typeof createAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetHealth<TData = Awaited<ReturnType<typeof getHealth>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData>>, request?: SecondParameter<typeof createAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetHealthQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Endpoint to handle OAuth2 authorization requests
 */
export const getAuthorize = (
    params: GetAuthorizeParams,
 options?: SecondParameter<typeof createAxiosInstance>,signal?: AbortSignal
) => {
      
      
      return createAxiosInstance<unknown>(
      {url: `/authorize`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAuthorizeQueryKey = (params: GetAuthorizeParams,) => {
    return [`/authorize`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAuthorizeQueryOptions = <TData = Awaited<ReturnType<typeof getAuthorize>>, TError = ErrorType<void | ErrorResponse>>(params: GetAuthorizeParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthorize>>, TError, TData>>, request?: SecondParameter<typeof createAxiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAuthorizeQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAuthorize>>> = ({ signal }) => getAuthorize(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAuthorize>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAuthorizeQueryResult = NonNullable<Awaited<ReturnType<typeof getAuthorize>>>
export type GetAuthorizeQueryError = ErrorType<void | ErrorResponse>


export function useGetAuthorize<TData = Awaited<ReturnType<typeof getAuthorize>>, TError = ErrorType<void | ErrorResponse>>(
 params: GetAuthorizeParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthorize>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAuthorize>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof createAxiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAuthorize<TData = Awaited<ReturnType<typeof getAuthorize>>, TError = ErrorType<void | ErrorResponse>>(
 params: GetAuthorizeParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthorize>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAuthorize>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof createAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAuthorize<TData = Awaited<ReturnType<typeof getAuthorize>>, TError = ErrorType<void | ErrorResponse>>(
 params: GetAuthorizeParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthorize>>, TError, TData>>, request?: SecondParameter<typeof createAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetAuthorize<TData = Awaited<ReturnType<typeof getAuthorize>>, TError = ErrorType<void | ErrorResponse>>(
 params: GetAuthorizeParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthorize>>, TError, TData>>, request?: SecondParameter<typeof createAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAuthorizeQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const postToken = (
    
 options?: SecondParameter<typeof createAxiosInstance>,) => {
      
      
      return createAxiosInstance<TokenResponse>(
      {url: `/token`, method: 'POST'
    },
      options);
    }
  


export const getPostTokenMutationOptions = <TError = ErrorType<ErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postToken>>, TError,void, TContext>, request?: SecondParameter<typeof createAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postToken>>, TError,void, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postToken>>, void> = () => {
          

          return  postToken(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostTokenMutationResult = NonNullable<Awaited<ReturnType<typeof postToken>>>
    
    export type PostTokenMutationError = ErrorType<ErrorResponse>

    export const usePostToken = <TError = ErrorType<ErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postToken>>, TError,void, TContext>, request?: SecondParameter<typeof createAxiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postToken>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getPostTokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postLogin = (
    loginRequest: LoginRequest,
 options?: SecondParameter<typeof createAxiosInstance>,) => {
      
      
      return createAxiosInstance<LoginResponse>(
      {url: `/login`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: loginRequest
    },
      options);
    }
  


export const getPostLoginMutationOptions = <TError = ErrorType<ErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postLogin>>, TError,{data: LoginRequest}, TContext>, request?: SecondParameter<typeof createAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postLogin>>, TError,{data: LoginRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postLogin>>, {data: LoginRequest}> = (props) => {
          const {data} = props ?? {};

          return  postLogin(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostLoginMutationResult = NonNullable<Awaited<ReturnType<typeof postLogin>>>
    export type PostLoginMutationBody = LoginRequest
    export type PostLoginMutationError = ErrorType<ErrorResponse>

    export const usePostLogin = <TError = ErrorType<ErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postLogin>>, TError,{data: LoginRequest}, TContext>, request?: SecondParameter<typeof createAxiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postLogin>>,
        TError,
        {data: LoginRequest},
        TContext
      > => {

      const mutationOptions = getPostLoginMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postRegistration = (
    registrationRequest: RegistrationRequest,
 options?: SecondParameter<typeof createAxiosInstance>,) => {
      
      
      return createAxiosInstance<void>(
      {url: `/registration`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: registrationRequest
    },
      options);
    }
  


export const getPostRegistrationMutationOptions = <TError = ErrorType<ErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postRegistration>>, TError,{data: RegistrationRequest}, TContext>, request?: SecondParameter<typeof createAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postRegistration>>, TError,{data: RegistrationRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postRegistration>>, {data: RegistrationRequest}> = (props) => {
          const {data} = props ?? {};

          return  postRegistration(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostRegistrationMutationResult = NonNullable<Awaited<ReturnType<typeof postRegistration>>>
    export type PostRegistrationMutationBody = RegistrationRequest
    export type PostRegistrationMutationError = ErrorType<ErrorResponse>

    export const usePostRegistration = <TError = ErrorType<ErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postRegistration>>, TError,{data: RegistrationRequest}, TContext>, request?: SecondParameter<typeof createAxiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postRegistration>>,
        TError,
        {data: RegistrationRequest},
        TContext
      > => {

      const mutationOptions = getPostRegistrationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postRegistrationActivation = (
    registrationActivationRequest: RegistrationActivationRequest,
 options?: SecondParameter<typeof createAxiosInstance>,) => {
      
      
      return createAxiosInstance<void>(
      {url: `/registration/activate`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: registrationActivationRequest
    },
      options);
    }
  


export const getPostRegistrationActivationMutationOptions = <TError = ErrorType<ErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postRegistrationActivation>>, TError,{data: RegistrationActivationRequest}, TContext>, request?: SecondParameter<typeof createAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postRegistrationActivation>>, TError,{data: RegistrationActivationRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postRegistrationActivation>>, {data: RegistrationActivationRequest}> = (props) => {
          const {data} = props ?? {};

          return  postRegistrationActivation(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostRegistrationActivationMutationResult = NonNullable<Awaited<ReturnType<typeof postRegistrationActivation>>>
    export type PostRegistrationActivationMutationBody = RegistrationActivationRequest
    export type PostRegistrationActivationMutationError = ErrorType<ErrorResponse>

    export const usePostRegistrationActivation = <TError = ErrorType<ErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postRegistrationActivation>>, TError,{data: RegistrationActivationRequest}, TContext>, request?: SecondParameter<typeof createAxiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postRegistrationActivation>>,
        TError,
        {data: RegistrationActivationRequest},
        TContext
      > => {

      const mutationOptions = getPostRegistrationActivationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postChangePassword = (
    changePasswordRequest: ChangePasswordRequest,
 options?: SecondParameter<typeof createAxiosInstance>,) => {
      
      
      return createAxiosInstance<void>(
      {url: `/change-password`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: changePasswordRequest
    },
      options);
    }
  


export const getPostChangePasswordMutationOptions = <TError = ErrorType<ErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postChangePassword>>, TError,{data: ChangePasswordRequest}, TContext>, request?: SecondParameter<typeof createAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postChangePassword>>, TError,{data: ChangePasswordRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postChangePassword>>, {data: ChangePasswordRequest}> = (props) => {
          const {data} = props ?? {};

          return  postChangePassword(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostChangePasswordMutationResult = NonNullable<Awaited<ReturnType<typeof postChangePassword>>>
    export type PostChangePasswordMutationBody = ChangePasswordRequest
    export type PostChangePasswordMutationError = ErrorType<ErrorResponse>

    export const usePostChangePassword = <TError = ErrorType<ErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postChangePassword>>, TError,{data: ChangePasswordRequest}, TContext>, request?: SecondParameter<typeof createAxiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postChangePassword>>,
        TError,
        {data: ChangePasswordRequest},
        TContext
      > => {

      const mutationOptions = getPostChangePasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postResetPassword = (
    resetPasswordRequest: ResetPasswordRequest,
 options?: SecondParameter<typeof createAxiosInstance>,) => {
      
      
      return createAxiosInstance<void>(
      {url: `/reset-password`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: resetPasswordRequest
    },
      options);
    }
  


export const getPostResetPasswordMutationOptions = <TError = ErrorType<ErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResetPassword>>, TError,{data: ResetPasswordRequest}, TContext>, request?: SecondParameter<typeof createAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postResetPassword>>, TError,{data: ResetPasswordRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResetPassword>>, {data: ResetPasswordRequest}> = (props) => {
          const {data} = props ?? {};

          return  postResetPassword(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof postResetPassword>>>
    export type PostResetPasswordMutationBody = ResetPasswordRequest
    export type PostResetPasswordMutationError = ErrorType<ErrorResponse>

    export const usePostResetPassword = <TError = ErrorType<ErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResetPassword>>, TError,{data: ResetPasswordRequest}, TContext>, request?: SecondParameter<typeof createAxiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postResetPassword>>,
        TError,
        {data: ResetPasswordRequest},
        TContext
      > => {

      const mutationOptions = getPostResetPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postResetPasswordSubmit = (
    resetPasswordSubmissionRequest: ResetPasswordSubmissionRequest,
 options?: SecondParameter<typeof createAxiosInstance>,) => {
      
      
      return createAxiosInstance<void>(
      {url: `/reset-password/submit`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: resetPasswordSubmissionRequest
    },
      options);
    }
  


export const getPostResetPasswordSubmitMutationOptions = <TError = ErrorType<ErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResetPasswordSubmit>>, TError,{data: ResetPasswordSubmissionRequest}, TContext>, request?: SecondParameter<typeof createAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postResetPasswordSubmit>>, TError,{data: ResetPasswordSubmissionRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postResetPasswordSubmit>>, {data: ResetPasswordSubmissionRequest}> = (props) => {
          const {data} = props ?? {};

          return  postResetPasswordSubmit(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostResetPasswordSubmitMutationResult = NonNullable<Awaited<ReturnType<typeof postResetPasswordSubmit>>>
    export type PostResetPasswordSubmitMutationBody = ResetPasswordSubmissionRequest
    export type PostResetPasswordSubmitMutationError = ErrorType<ErrorResponse>

    export const usePostResetPasswordSubmit = <TError = ErrorType<ErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postResetPasswordSubmit>>, TError,{data: ResetPasswordSubmissionRequest}, TContext>, request?: SecondParameter<typeof createAxiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postResetPasswordSubmit>>,
        TError,
        {data: ResetPasswordSubmissionRequest},
        TContext
      > => {

      const mutationOptions = getPostResetPasswordSubmitMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postConnectOAuth = (
    type: string,
 options?: SecondParameter<typeof createAxiosInstance>,) => {
      
      
      return createAxiosInstance<OAuthResponse>(
      {url: `/oauth/connect/${type}`, method: 'POST'
    },
      options);
    }
  


export const getPostConnectOAuthMutationOptions = <TError = ErrorType<ErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postConnectOAuth>>, TError,{type: string}, TContext>, request?: SecondParameter<typeof createAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postConnectOAuth>>, TError,{type: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postConnectOAuth>>, {type: string}> = (props) => {
          const {type} = props ?? {};

          return  postConnectOAuth(type,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostConnectOAuthMutationResult = NonNullable<Awaited<ReturnType<typeof postConnectOAuth>>>
    
    export type PostConnectOAuthMutationError = ErrorType<ErrorResponse>

    export const usePostConnectOAuth = <TError = ErrorType<ErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postConnectOAuth>>, TError,{type: string}, TContext>, request?: SecondParameter<typeof createAxiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postConnectOAuth>>,
        TError,
        {type: string},
        TContext
      > => {

      const mutationOptions = getPostConnectOAuthMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postLoginOAuth = (
    type: string,
 options?: SecondParameter<typeof createAxiosInstance>,) => {
      
      
      return createAxiosInstance<OAuthResponse>(
      {url: `/oauth/login/${type}`, method: 'POST'
    },
      options);
    }
  


export const getPostLoginOAuthMutationOptions = <TError = ErrorType<ErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postLoginOAuth>>, TError,{type: string}, TContext>, request?: SecondParameter<typeof createAxiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postLoginOAuth>>, TError,{type: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postLoginOAuth>>, {type: string}> = (props) => {
          const {type} = props ?? {};

          return  postLoginOAuth(type,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostLoginOAuthMutationResult = NonNullable<Awaited<ReturnType<typeof postLoginOAuth>>>
    
    export type PostLoginOAuthMutationError = ErrorType<ErrorResponse>

    export const usePostLoginOAuth = <TError = ErrorType<ErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postLoginOAuth>>, TError,{type: string}, TContext>, request?: SecondParameter<typeof createAxiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postLoginOAuth>>,
        TError,
        {type: string},
        TContext
      > => {

      const mutationOptions = getPostLoginOAuthMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
