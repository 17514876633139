import { Card, CardBody, Center, ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NotificationsProvider } from "./providers/NotificationProvider";
import { Authenticate } from "./screens/Authenticate";
import { OAuthCallback } from "./screens/OAuthCallback";
import { PageNotFound } from "./screens/PageNotFound";
import { PasswordReset } from "./screens/PasswordReset";
import { Registration } from "./screens/Registration";

export default function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <NotificationsProvider>
            <CentralCard />
          </NotificationsProvider>
        </BrowserRouter>
      </ChakraProvider>
    </QueryClientProvider>
  );
}

const CentralCard = () => {
  return (
    <Center height="96vh">
      <Card width="400px" shadow="md" borderRadius="md">
        <CardBody>
          <Routes>
            <Route key="register" path="/register" element={<Registration />} />
            <Route
              key="reset-password"
              path="/reset-password"
              element={<PasswordReset />}
            />
            <Route key="login" path="/login" element={<Authenticate />} />
            <Route
              key="oauth-callback"
              path="/oauth-callback"
              element={<OAuthCallback />}
            />
            <Route key="not-found" path="*" element={<PageNotFound />} />
          </Routes>
        </CardBody>
      </Card>
    </Center>
  );
};
